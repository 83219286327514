body {
  background-color: #333;
  color: #fff;
}

.App {
  text-align: center;
}

.taskBg {
  background-color: #555;
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 10px 140px 10px 15px;
  text-align: left;
  font-size: 22px;
  position: relative;
}

.taskBg div {
  display: flex;
  align-items: center;
}

.taskBg .taskText {
  flex: 1;
}

.taskBg .done .taskText {
  text-decoration: line-through;
  opacity: 0.3;
}

.taskBg .iconsWrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  width: auto;
  display: inline-block;
}

.taskBg .iconsWrap span {
  display: inline-block;
  margin: 0px 10px 0 10px;
  cursor: pointer;
  color: aliceblue;
}

.taskBg .iconsWrap span:hover {
  color: lightcoral;
  font-size: 24px
}

.taskBg .iconsWrap span:active {
  font-size: 22px
}

.mr-20 {
  margin-right: 20px;
}